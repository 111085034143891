import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "gatsby-theme-starberry-lomondgroup/src/components/Counter/Counter.scss";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import "./WhyChoose.scss";

const WhyChoose = ({Module}) => {

    var counter_list = Module?.why_choose_module;

    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },

        ],
    };
    
    return (
        <section className="counter why_choose">
            <Container>
                <Row>
                    <Col>
                        <div className="counter-wrapper">
                                
                            <h2>{Module?.title}</h2>
                            <p>{Module?.description}</p>
                            
                            {counter_list?.length > 0 && 
                            <div className="counter-wrap-inner">
                                <Slider {...settings}>
                                    { counter_list?.map((item, index) => {
                                        return(
                                            <div className="slider_wrapper">
                                                <div className="counter-wrap">
                                                    {item.upload_icon?.url && 
                                                        <div className="counter-box">
                                                            <span className="box">
                                                                {item.upload_icon?.url && <ImageModule ImageSrc={item.upload_icon} alt={item.name} loading="eager" /> }
                                                            </span>
                                                        </div>
                                                    }
                                                    <div className="counter-content">
                                                        <h3>{item.title}</h3>
                                                        <p>{item.content}</p>
                                                    </div>
                                                </div>
                                            </div>                                    
                                        )})
                                    }
                                </Slider>
                            </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    );
};
export default WhyChoose;
